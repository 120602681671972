import novelaTheme from '@narative/gatsby-theme-novela/src/gatsby-plugin-theme-ui';

export default {
  ...novelaTheme,
  initialColorMode: `dark`,
  colors: {
    ...novelaTheme.colors,
    primary: "#000",
    secondary: "#73737D",
    grey: "#73737D",
    background: "#fafafa",
    accent: "#6166DC",
    hover: "rgba(0, 0, 0, 0.07)",
    gradient: "",
    articleText: "#08080B",
    track: "rgba(8, 8, 11, 0.3)",
    progress: "#000",
    card: "#fff",
    error: "#EE565B",
    success: "#46B17B",
    errorBackground: "rgba(238, 86, 91, 0.1)",
    horizontalRule: "rgba(8, 8, 11, 0.15)",
    inputBackground: "rgba(0, 0, 0, 0.05)",
    modes: {
      dark: {
        grey: "#73737D",
        primary: "#fff",
        secondary: "#fff",
        accent: "#E9DAAC",
        background: "#181818",
        hover: "rgba(255, 255, 255, 0.07)",
        gradient:
          "",
        articleText: "#fff",
        track: "rgba(255, 255, 255, 0.3)",
        progress: "#fff",
        card: "#1D2128",
        error: "#EE565B",
        success: "#46B17B",
        errorBackground: "rgba(238, 86, 91, 0.1)",
        horizontalRule: "rgba(255, 255, 255, 0.15)",
        inputBackground: "rgba(255, 255, 255, 0.07)",
      },
    },
  },
};